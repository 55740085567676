import React, { useState } from 'react'
import FaqsContext from './FaqsContext'

const FaqsState = (props) => {
  const dataInitial = []
  const [faqsData, setFaqsData] = useState(dataInitial)
  // const [ error, setError] = useState()
  const faqsUrl = process.env.REACT_APP_FAQS_URL

  const fetchData = async () => {
    try {
      const response = await fetch(faqsUrl, {
        method: "GET"
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const jsonData = await response.json();
      setFaqsData(jsonData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <FaqsContext.Provider value={{ faqsData, fetchData }}>
      {props.children}
    </FaqsContext.Provider>
  )
}

export default FaqsState