import React, { useContext, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fade } from 'react-reveal';
import FaqsContext from './context/FaqsContext';
import { faqsQue } from './utils/Constant';

const Faqs = () => {
    const context = useContext(FaqsContext)
    const { faqsData, fetchData } = context
    const [faqs, setFaqs] = useState([])

    const accordionItem = {
        backgroundColor: '#041733',
        borderRadius: '20px',
        border: '1px solid rgb(10, 158, 158)',
        margin: '30px 0px 30px 0px',
        color: 'white',
        boxShadow: ' 0px 0px 106px -3px #0633ad',
        Padding: '100px',
    }
    const getData = () => {
        if (faqsData.length !== 0) {
            setFaqs(faqsData)
        } else {
            setFaqs(faqsQue)
        }
    }

    useEffect(() => {

        fetchData();
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line 
    }, [faqsData])

    return (
        <>

            <h1 className='text-center lg:text-6xl md:text-5xl sm:text-[2.9rem] text-3xl font-bold mb-20 mt-20 text-white mx-6'>Frequently Asked Question</h1>
            <div className='container m-auto cursor-pointer lg:px-48 px-6  mb-40' >
                {faqs.map((item) => {
                    return <Fade bottom key={item.id}>
                        <Accordion sx={{ padding: "0 10px 0 10px" }} style={accordionItem} disableGutters>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white", border: "2px solid white", borderRadius: "50%", fontSize: "40px", fontWeight: "100" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ margin: "0px" }}
                            >
                                <Typography variant='h6' className='py-2 my-40'><b>{item.title}</b></Typography>
                            </AccordionSummary >
                            <AccordionDetails sx={{ padding: "0px 16px 16px", fontSize: "18px" }}>
                                <p className='font-extralight' dangerouslySetInnerHTML={{ __html: item.description }}>
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </Fade>
                })}
            </div>
        </>


    )
}

export default Faqs