export const faqsQue = [
    {
        id:1,
        title:"What is the use of Layto app? ",
        description :"Layto is India’s #1 Professional platform that connects job seekers with employers. It helps job seekers to apply for jobs & talk to the corresponding HRs of the companies directly and vice versa."
    },
    {
        id:2,
        title:" Is Layto app real? ",
        description :"Layto app is 100% genuine and has 0% tolerance towards fake employers and job seekers who create ruckus and cause problems to others."
    },
    {
        id:3,
        title:"How is Layto different from other traditional portals?",
        description :"Traditional hiring is long, expensive, and inefficient. Layto simplifies the process by connecting employers directly with job seekers with relevant skills and experience."
    },
    {
        id:4,
        title:"How do I get a job using Layto app? ",
        description :"It is simple to find jobs on Layto app. Follow these steps to get a job using Layto app Download Layto app from Google Play Store."
    }
]