import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReferCode } from './shared';


const Downloads = () => {
    const navigate = useNavigate();
    const code = getReferCode();

    const redirectByPlatform = () => {
        const platform = getMobileOperatingSystem();
        let url = ""
        if (platform) {
            url = "/" + platform
            if (code) url += `?code=${code}`
        } else {
            url = "/downloads"
        }
        navigate(url)
    }

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }
        return null;
    }

    useEffect(() => {
        redirectByPlatform();
        // eslint-disable-next-line
    }, [])

    return (
        <div className='relative h-[100vh] bg-[url("/public/asset/comingsoon.webp")] bg-no-repeat bg-cover bg-center'>
            <div className=' absolute top-5 left-5'>
                <a href="/">
                    <img src="./asset/logo.webp" alt="" className=' h-10 ' />
                </a>
            </div>
            <div className=' flex justify-center flex-col items-center h-[100vh]'>
                <div className="text-center text-white lg:text-5xl sm:text-4xl text-3xl  lg:mx-80 md:mx-52 sm:mx-20 mx-10 leading-tight">The download is only available for iOS and Android.</div>
                <div className='lg:w-[20rem] w-64 lg:mt-10 md:mt-16 sm:mt-14 mt-14 rounded-xl bg-gradient-to-b from-cyan-200  to-cyan-200 p-[1px] shadow-2xl  hover:drop-shadow-xl  transform transition duration-500 lg:hover:scale-110 '>
                    <div className='bg-[#4488f7] rounded-xl bg-shadow mx-auto'>
                        <a href="/" className='nav-link'> <button className='btn rounded-xl lg:text-[22px] md:text-[20px] sm:text-xl text-[13px] text-white lg:mx-0 md:mx-0 sm:mx-auto p-5  font-bold lg:w-full w-full mx-auto tracking-wider'><span>GO TO HOME PAGE</span></button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Downloads