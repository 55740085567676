import React from 'react'


const ComingSoon = () => {
    return (
        <>
            <div className='relative h-[100vh] bg-[url("/public/asset/comingsoon.webp")] bg-no-repeat bg-cover bg-center'>
                <div className=' absolute top-5 left-5'>
                    <a href='/'>
                        <img src="./asset/logo.webp" alt="" className=' h-10 ' />
                    </a>
                </div>

                <div className="flex justify-center items-center flex-col h-full ">

                    <h1 className='comingSoonHead mx-auto'>
                        <span className='comingSoonText  effect-shine font-bold uppercase tracking-wider'> Coming Soon...</span>
                    </h1>

                </div>
            </div>
        </>
    )
}

export default ComingSoon