import { useState, useRef, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { QRCodeCanvas } from 'qrcode.react';

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1)

    const ref = useRef(null)

    const handleModal = () => {
        ref.current.click()
    }

    const navigation = [
        { id: 1, name: 'Home', href: '#home' },
        { id: 2, name: 'Job Seeker', href: '#jobseeker' },
        { id: 3, name: 'Job Provider', href: '#jobprovider' },
        { id: 4, name: 'FAQs', href: '#faqs' },
        { id: 5, name: 'Download', href: '#download' },
        { id: 6, name: 'Contact us', href: '#contactus' }
    ]

    const handleAnchorClick = (event, id) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substr(1);
        const targetElement = document.getElementById(targetId);
        targetElement.scrollIntoView({ behavior: 'smooth' });
        setIsActive(id)
        setMobileMenuOpen(false)
    };

    const isMobileView = () => window.innerWidth <= 1023;

    useEffect(() => {
        if (!isMobileView()) return;
        const handleScroll = () => {
            let currentId = 1;
            navigation.forEach((item) => {
                const section = document.getElementById(item.href.substr(1));
                if (section) {
                    const { top, bottom } = section.getBoundingClientRect();
                    if (top <= 50 && bottom >= 50) {
                        currentId = item.id;
                    }
                }
            });
            setIsActive(currentId);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navigation]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        };
        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [])
    return (
        <header className="fixed inset-x-0 top-0 z-50 bg-[#041733] border-b" >
            <nav className="max-w-screen-3xl flex flex-wrap items-center justify-between lg:mx-10 md:mx-5 sm:mx-5 mx-2" aria-label="Global" id='navbar'>
                <div className="flex flex-1 " >
                    <span className="sr-only">Your Company</span>
                    <a href="/">
                        <img
                            className="lg:w-full md:h-[3rem] sm:h-10 h-10 w-auto lg:ps-5 md:ps-5 sm:ps-6 ps-3 py-1"
                            src="./asset/logo.webp"
                            alt=""
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="m-2.5 inline-flex items-center justify-center rounded-md p-2 text-gray-200 bg-blue-500"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-4 lg:justify-center">
                    {navigation.map((item) => (
                        <a key={item.id} href={item.href} className={`nav-link text-[16px] font-semibold leading-6 text-gray-100 pb-2 pe-1 `} onClick={handleAnchorClick}>
                            {item.name}
                        </a>

                    ))}

                </div>
                <div className="hidden lg:flex lg:flex-1 justify-end mx-3 my-3 ">
                    <div className='w-60 bg-gradient-to-b from-[#1EBFDA]  to-[#97EEF9] p-[1px] rounded-xl '>
                        <div className="bg-shadow">
                            <button className='bg-[#4488f7] btn rounded-xl lg:text-[18px] md:text-[15px] sm:text-xl text-white lg:mx-0 md:mx-0 sm:mx-auto py-2 font-bold w-full text-xl mx-auto' onClick={handleModal}><span>DOWNLOAD</span></button>
                        </div>
                    </div>
                </div>

                <>
                    <button ref={ref}
                        className="bg-blue-200 text-black active:bg-blue-500 
                      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 hidden" type="button" onClick={() => setShowModal(true)}>Fill Details</button>
                    {showModal ? (
                        <>
                            <div tabIndex='-1' className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-md backdrop-brightness-50">
                                <div className=" relative lg:w-80 md:w-auto sm:w-auto w-80 my-6  max-w-3xl">
                                    <div className=" border border-cyan-500 rounded-xl  shadow-lg relative flex flex-col  w-80 bg-[#033E84] outline-none focus:outline-none">
                                        <div className="flex items-start justify-between p-2 border-b border-solid border-gray-300 bg-[#041935] rounded-t-xl">
                                            <div></div>
                                            <h3 className=" lg:text-xl md:text-xl sm:text-xl text-xl  ps-7 text-white ">Download the App</h3>
                                            <button
                                                className="bg-transparent border-0 text-white float-right"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <span className="text-white opacity-7 h-6 w-6 lg:text-2xl text-xl  block  py-0 rounded-full">
                                                    <i className="fa-solid fa-circle-xmark"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="relative p-6 flex lg:flex-col md:flex-col sm:flex-col flex-col justify-center">
                                            <div className="flex-initial w-full">
                                                <div className=" rounded px-5 w-full">
                                                    <h3 className='text-2xl  mb-6 text-white text-center'>Scan the QR to download app!</h3>
                                                </div>
                                            </div>
                                            <div className="flex-initial w-full flex items-center">
                                                <div className="rounded-xl w-full h-full border border-black bg-white mx-2">
                                                    <QRCodeCanvas value={`${window.location.origin}/downloads`} className='w-60 h-60 mx-auto p-4' size={250} />
                                                </div>
                                            </div>
                                            <div className='mt-6'>
                                                <Link className='' to="/ios" target='_blank'><span className=' uppercase text-white font-semibold flex items-center tracking-wider hover:text-gray-300'><i className="fa-brands fa-apple text-3xl pe-2"></i> <span>download for ios </span></span></Link>
                                            </div>
                                            <div className='mt-2'>
                                                <Link className='' to="/android" target='_blank'><span className=' uppercase text-white font-semibold flex items-center tracking-wider hover:text-gray-300'><i className="fa-brands fa-google-play text-2xl pe-2"></i><span>download for android</span></span></Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </>

                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#041734] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 lg:h-full sm:h-full sm:border-s border-b h-fit">
                        <div className="flex items-center justify-end">
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2 text-gray-700 bg-blue-500"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a key={item.id} href={item.href} className={`${isActive === item.id ? "active" : ""} nav-link mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 `} onClick={(e) => handleAnchorClick(e, item.id)}
                                        > {item.name}  </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </nav>
        </header>
    )
}
export default Navbar