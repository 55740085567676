import './App.css';

import Landing from './components/Landing';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import ScanQR from './components/installApp/ScanQR';
import IosApp from './components/installApp/IosApp';
import AndroidApp from './components/installApp/AndroidApp';
import Downloads from './components/installApp/Downloads';
import ReferCode from './components/installApp/ReferCode';
import ComingSoon from './components/ComingSoon';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/coming-soon' element={<ComingSoon />} />
          <Route exact path='/ios' element={<IosApp />} />
          <Route exact path='/android' element={<AndroidApp />} />
          <Route exact path='/downloads' element={<Downloads />} />
          <Route exact path='/refer' element={<ReferCode />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
