import React, { useEffect, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { getReferCode } from './shared';

const ReferCode = () => {
    const [query, setQuery] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    // async function pasteText() {
    //     try {
    //       const permission = await navigator.permissions.query({
    //         name: "clipboard-read",
    //       });
    //       if (permission.state === "denied") {
    //         throw new Error("Not allowed to read clipboard.");
    //       }

    //       const clipText = await navigator.clipboard.readText();
    //       setQuery(clipText.toUpperCase());

    //     } catch (error) {
    //       console.error(error.message);
    //     }
    //   }
    let code
    useEffect(() => {
        code = getReferCode()
        setQuery(code || '')
        setButtonDisabled((code || '') !== '')
    }, [])

    return (

        <div className=' relative h-screen bg-[url("/public/asset/comingsoon.webp")] bg-no-repeat bg-cover bg-center'>
            <div className=' absolute top-5 left-5'>
                <a href='/'>
                    <img src="./asset/logo.webp" alt="" className=' h-10 ' />
                </a>
            </div>
            <div className="flex justify-center items-center flex-col h-full ">
                <h1 className='text-white text-center text-2xl mb-10'>Scan the QR to download app!</h1>
                <div>
                    <label htmlFor="small-input" className="block mb-2 text-3xl font-medium text-gray-100 dark:text-white text-center">Refer Code</label>
                    <div className='flex flex-row justify-center mx-auto'>
                        <div className=''>
                            <input type="text" id="small-input" className="block text-md w-[250px] p-2 text-black font-bold border border-gray-300 rounded-lg   focus:ring-blue-500 focus:border-blue-500 " disabled={buttonDisabled} value={query} onChange={handleChange} />
                        </div>

                    </div>
                    <div className=" my-10 flex justify-center">
                        {query && <QRCodeCanvas value={`${window.location.origin}/downloads?code=${query}`} className='p-4 bg-white rounded-2xl' size={250} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferCode