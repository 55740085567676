import React from 'react'
import { Fade, Flip } from 'react-reveal';
import { Link } from 'react-router-dom';


const Download = () => {
    return (
        <>
            <div className='relative mt-10'>
                <div className='bg-[url("/public/asset/bg.webp")]' >
                    <div className='lg:mx-9 md:mx-8 sm:mx-4 mx-2 lg:max-h-[41rem] md:max-h-[70rem] sm:max-h-[70rem] max-h-[53rem] overflow-hidden'>
                        <div className=''>
                            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 grid-cols-1 h-full ">
                                <div className='flex flex-col lg:py-20 sm:py-10 py-0 px-4'>
                                    <Fade left>
                                        <h1 className='lg:text-6xl md:text-5xl sm:text-4xl text-4xl font-bold mt-10 text-white lg:text-left md:text-left sm:text-center '>Download Layto now for Free</h1>
                                    </Fade>
                                    <div className='my-3'>
                                        <Fade left>
                                            <p className='lg:text-2xl md:text-2xl sm:text-lg text-base lg:mt-6 mt-2 text-white text-justify'>The next generation of Job portal is here. Now, you have the chance to be part of it via Layto. Download our app for your device and enjoy the best mobile experience for Layto.</p>
                                        </Fade>
                                    </div>
                                    <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col my-10">
                                        <Flip bottom>
                                            <div className='me-2 w-full'>
                                                <Link to='/ios' target='_blank' className='bg-white text-[15px] uppercase tracking-wide py-[15px] px-[18px] text-black md:me-2 rounded-2xl font-bold p-3 w-full lg:my-0 md:my-0 sm:my-2 my-2  transform transition duration-500 lg:hover:scale-95 flex items-center justify-center'><i className="fa-brands fa-apple pe-3 text-3xl"></i><span>Download For IOS</span></Link>
                                            </div>
                                        </Flip>
                                        <Flip bottom>
                                            <div className='min-w-[50px] me-2 w-full'>
                                                <Link to='/android' target='_blank' className='bg-white text-[15px] uppercase tracking-wide py-[15px] px-[18px] text-black rounded-2xl font-bold p-3 w-full lg:my-0 md:my-0 sm:my-2 my-2  transform transition duration-500 lg:hover:scale-95 flex items-center justify-center'><i className="fa-brands fa-google-play pe-3 text-3xl"></i><span>Download For Android</span></Link>
                                            </div>
                                        </Flip>
                                    </div>
                                </div>

                                <div className=' relative overflow-y-hidden'>
                                    <div className='h-full'>
                                        <img src="./asset/download-mobile.webp" alt="" className='lg:w-96 md:w-96 sm:w-96 lg:p-0 md:p-0  h-auto w-60  mx-auto rotate-6 md:mt-20 sm:mt-14 mt-5' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        </>

    )
}

export default Download