import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReferCode } from './shared';

const IosApp = () => {
  const url = process.env.REACT_APP_IOS_APP_URL;

  const navigate = useNavigate();
  const code = getReferCode()

  const checkURL = () => {
    if (url) {
      window.location.href = url;
      setTimeout(() => {
        window.close();
      }, 2000)
    } else {
      navigate('/coming-soon')
    }
  }

  useEffect(() => {
    checkURL();
    // eslint-disable-next-line
  }, [])

  return (
    <></>
  )
}

export default IosApp