import React, { useEffect } from 'react';
import styled from 'styled-components';

const ProgressWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    right: 10px;
    height: 50px;
    width: 50px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
`;

const ProgressValue = styled.div`
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 22px;
    color: white;
    background-color: #041733;
`;

const ScrollButton = () => {
  const calcScrollValue = () => {
    const scrollProgress = document.getElementById('progress');

    const pos = document.documentElement.scrollTop;
    const calcHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollValue = Math.round((pos * 100) / calcHeight);

    if (pos > 100) {
      scrollProgress.style.display = 'grid';
    } else {
      scrollProgress.style.display = 'none';
    }

    scrollProgress.style.background = `conic-gradient( #FA9805 ${scrollValue}%, #9ca3af ${scrollValue}%)`;
    scrollProgress.style.zIndex = 1000;
  };

  const handleScrollTop = () => {
    const scrollToTop = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 0) {
        window.scrollTo(0, currentPosition - 80);
        requestAnimationFrame(scrollToTop);
      }
    };

    scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', calcScrollValue);
    window.addEventListener('load', calcScrollValue);

    return () => {
      window.removeEventListener('scroll', calcScrollValue);
      window.removeEventListener('load', calcScrollValue);
    };
  }, []);

  return (
    <ProgressWrapper id="progress" onClick={handleScrollTop}>
      <ProgressValue>
        <i className="fa-solid fa-chevron-up"></i>
      </ProgressValue>
    </ProgressWrapper>
  );
};

export default ScrollButton;
