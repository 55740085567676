import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getReferCode } from './shared';


const AndroidApp = () => {
    const url = process.env.REACT_APP_ANDROID_APP_URL;
    const navigate = useNavigate();
    const code = getReferCode()

    const checkURL = () => {
        if (url) {
            window.location.href = url;
        } else {
            navigate('/coming-soon')
        }
    }

    useEffect(() => {
        checkURL();
        // eslint-disable-next-line
    }, [])

    return (
        <></>
    )
}

export default AndroidApp