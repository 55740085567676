import React from 'react'
import { Fade, Flip } from 'react-reveal'
import { Link } from 'react-router-dom';


const Hero = () => {
    const handleAnchorClick = (event) => {
        event.preventDefault();
        // const targetId = event.target.getAttribute('href').substr(1);
        const targetElement = document.getElementById("download");
        targetElement.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 grid-cols-1">
                <div className=' bg-[url("/public/asset/hero-shadow.webp")] bg-right'>

                    <div className='flex flex-col lg:ps-14 md:ps-10 sm:ps-10 ps-4 lg:pe-0 sm:pe-6 pe-5 lg:my-10 md:my-10 asm:my-5 my-3 lg:pt-20 md:pt-14 sm:pt-10 pt-5'>
                        <Fade left cascade >
                            <p className='text-xl uppercase text-white py-5 tracking-widest text-left font-semibold'>Welcome to Layto jobs</p>
                        </Fade>
                        <Fade left delay={800}>
                            <h1 className='lg:text-[5rem] md:text-7xl sm:text-6xl text-5xl font-bold text-white text-left pb-2'>Your <span className=' text-blue-500'> skill</span>.</h1>
                        </Fade>
                        <Fade left delay={1400}>
                            <h1 className='lg:text-[5rem] md:text-7xl sm:text-6xl text-5xl font-bold text-white text-left pb-2'>Your <span className=' text-blue-500'> dream</span>.</h1>
                        </Fade>
                        <Fade left delay={1800}>
                            <h1 className='lg:text-[5rem] md:text-7xl sm:text-6xl text-5xl font-bold text-white text-left pb-2'>Your <span className=' text-blue-500'> freedom</span>.</h1>
                        </Fade>
                        <Fade left cascade>
                            <p className='lg:text-2xl md:text-xl sm:text-xl text-xl lg:mt-6 mt-2  text-white lg:text-start sm:text-left text-left hyphens-manual'>Unlock Your Career Potential with us.</p>
                        </Fade>
                    </div>
                    <Flip bottom duration={2000}>
                        <div className='lg:my-0 md:my-14 sm:mt-6 mt-3 mb-10 lg:ps-14 md:ps-10 sm:ps-0 ps-0 flex lg:justify-start md:justify-center justify-center'>
                            <div className='lg:w-[28.75rem] w:80 lg:mt-0 mt-10 rounded-xl bg-gradient-to-b from-cyan-200  to-cyan-200 p-[1px] shadow-2xl  hover:drop-shadow-xl  transform transition duration-500 lg:hover:scale-110'>
                                <div className='bg-[#4488f7] rounded-xl bg-shadow mx-auto'>
                                    <Link to="#download" className='nav-link' onClick={handleAnchorClick}> <button className='btn rounded-xl lg:text-[22px] md:text-[20px] sm:text-xl text-[13px] text-white lg:mx-0 md:mx-0 sm:mx-auto p-5  font-bold lg:w-full w-full mx-auto tracking-wider'><span> DOWNLOAD FOR IOS & ANDROID</span></button></Link>
                                </div>
                            </div>
                        </div>
                    </Flip>

                </div>
                <div className='flex justify-center drop-shadow-2xl lg:my-12 md:my-12 pt-24 '>
                    <img src="./asset/hero-mobile.webp" alt="" className='mx-auto lg:w-[50vh] sm:w-[40vh] w-[40vh] hero-mobile-shadow h-auto rotate-[4deg] sm:rounded-[32px]' />
                </div>
            </div>
        </div>
    )
}

export default Hero