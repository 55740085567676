import { Fade } from 'react-reveal'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-transparent text-center text-gray-200 lg:text-left border-t relative">
            <div className='bg-shadow bg-[url("/public/asset/hero-shadow.webp")] lg:bg-[right_top_2rem] bg-[center_bottom_-35rem] bg-cover bg-no-repeat'>

                <div className="mx-6 py-10 text-center md:text-left ">
                    <div className="grid-1 grid lg:divide-x  divide-blue-100 gap-4 md:grid-cols-1 lg:grid-cols-3">
                        <div className="lg:ms-8 md:mx-7 ms-0">
                            <Fade delay={300}>
                                <h6 className="mb-4 mt-3 flex items-center justify-center lg:justify-start font-semibold uppercase md:justify-center text-xl">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="mr-3 h-4 w-4">
                                        <path
                                            d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
                                    </svg>
                                    Layto Jobs LLP
                                </h6>
                                <p className='lg:text-justify md:text-center sm:text-center text-center'>
                                    Layto is at the forefront of innovation in the world of job matching. Our mission is to connect talented job seekers like you with opportunities that align with your skills, experience, and aspirations. With Layto, your next career move is just a few clicks away.
                                </p>
                            </Fade>
                        </div>

                        <hr className='lg:hidden md:block sm:block block' />

                        <div className='lg:border-t-0 border-gray-400 lg:pl-5 md:pl-0 pl-0'>
                            <Fade delay={600}>
                                <h6 className="mb-4 md:mt-3 flex justify-center lg:justify-start font-semibold uppercase md:justify-center text-xl">
                                    Follow us on
                                </h6>
                                <div className="grid grid-cols-2">
                                    <p className="mb-4 flex items-center justify-center lg:justify-start md:justify-center hover:text-blue-500 ">
                                        <a href={process.env.REACT_APP_FACEBOOK_URL} target='_blank' rel="noreferrer" className=" text-neutral-200 hover:text-blue-500 dark:text-neutral-200">
                                            <i className='fa-brands fa-facebook text-xl mr-4'></i><span className='text-[18px]'>Facebook</span>
                                        </a>
                                    </p>

                                    <p className="mb-4 flex items-center justify-center lg:justify-start md:justify-center hover:text-blue-500 ">
                                        <a href={process.env.REACT_APP_TWITTER_URL} target='_blank' rel="noreferrer" className=" text-neutral-200 hover:text-[#00acee] dark:text-neutral-200">
                                            <i className='fa-brands fa-twitter text-xl mr-4'></i><span className='text-[18px]'>Twitter</span>
                                        </a>
                                    </p>
                                    <p className="mb-5 flex items-center justify-center lg:justify-start md:justify-center">
                                        <a href={process.env.REACT_APP_INSTAGRAM_URL} target='_blank' rel="noreferrer" className=" text-neutral-200 hover:text-[#c9567c] dark:text-neutral-200">
                                            <i className="fa-brands fa-instagram text-xl mr-4"></i><span className='text-[18px]'>Instagram</span>
                                        </a>
                                    </p>

                                    <p className="mb-4 flex items-center justify-center lg:justify-start md:justify-center hover:text-blue-500 ">
                                        <a href={process.env.REACT_APP_YOUTUBE_URL} target='_blank' rel="noreferrer" className=" text-neutral-200 hover:text-red-500 dark:text-neutral-200">
                                            <i className='fa-brands fa-youtube text-xl mr-4'></i><span className='text-[18px]'>Youtube</span>
                                        </a>
                                    </p>


                                </div>

                                <hr className='lg:block md:block sm:block block my-4 lg:border-gray-500 md:border-white' />

                                <h6 className="mb-4 md:mt-5 flex justify-center lg:justify-start font-semibold uppercase md:justify-center text-xl">
                                    Get In Touch
                                </h6>

                                <p className="mb-4 flex items-center justify-center lg:justify-start md:justify-center hover:text-blue-500 ">
                                    <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} className=" text-neutral-200 hover:text-blue-500 dark:text-neutral-200">
                                        <i className="fa-solid fa-envelope text-xl mr-4"></i>
                                        <span className='text-[18px]'>{process.env.REACT_APP_SUPPORT_EMAIL}</span>
                                    </a>
                                </p>
                                <p className="mb-4 flex items-center justify-center lg:justify-start md:justify-center">
                                    <a href="tel:+91-9898990099" className=" text-neutral-200 hover:text-blue-400 dark:text-neutral-200">
                                        <i className="fa-solid fa-phone text-xl mr-4"></i>
                                        <span className='text-[18px]'>+91-9898990099</span>
                                    </a>
                                </p>

                            </Fade>
                        </div>
                        <hr className='lg:hidden md:block sm:block block' />


                        <div className='lg:border-t-0 md:border-t-0 border-gray-400 lg:pl-5 pl-0  lg:me-10 md:me-0 sm:me-0 me-0'>
                            <Fade delay={900}>
                                <h6
                                    className="mb-4 md:mt-3  flex justify-center lg:justify-start font-semibold uppercase md:justify-center text-xl">
                                    Download our App
                                </h6>
                                <div className='lg:w-full md:w-80 w-full  mx-auto rounded-2xl bg-gradient-to-b from-gray-700  to-[#0F8CFE] p-[1px] shadow-2xl  hover:drop-shadow-xl transform transition duration-500  my-3'>
                                    <Link to='/ios' target='_blank'>
                                        <div className='bg-[#041733] transform transition duration-500 hover:bg-[#0a264e] rounded-2xl mx-auto lg:ps-0 ps-5 cursor-pointer'>
                                            <Link to='/ios' target='_blank' className='lg:text-xl md:text-xl sm:text-[18px] text-white lg:mx-2 md:mx-2  font-bold lg:p-3 sm:p-2 p-2  w-full lg:my-2 md:my-0 sm:my-2 my-2 mx-auto'>
                                                <span className='flex items-center  uppercase tracking-wider ps-4'> <i className="fa-brands fa-apple pe-3 lg:text-5xl sm:text-5xl text-4xl"></i> <span className='text-left'> <span className=' font-light text-sm text-gray-400'>Download on</span> <br /> app store </span></span></Link>
                                        </div>
                                    </Link>
                                </div>
                                <div className='lg:w-full md:w-80 w-full  mx-auto rounded-2xl bg-gradient-to-b from-gray-700  to-[#0F8CFE] p-[1px] shadow-2xl  hover:drop-shadow-xl transform transition duration-500 lg:hover:scale-95 my-3 '>
                                    <Link to='/android' target='_blank'>
                                        <div className='bg-[#041733] hover:bg-[#0a264e] rounded-2xl mx-auto lg:ps-0 ps-5 cursor-pointer'>
                                            <Link to='/android' target='_blank' className='lg:text-xl md:text-xl sm:text-[18px] text-white lg:mx-2 md:mx-2  font-bold lg:p-3 sm:p-2 p-2 w-full lg:my-2 md:my-0 sm:my-2 my-2 mx-auto'>
                                                <span className='flex items-center  uppercase tracking-wider ps-4'> <i className="fa-brands fa-google-play pe-3 lg:text-5xl sm:text-4xl text-3xl"></i> <span className='text-left'> <span className=' font-light text-sm text-gray-400'>GET IN ON</span> <br /> GOOGLE PLAY </span></span></Link>
                                        </div>
                                    </Link>
                                </div>
                            </Fade>

                            {/* <h6
                                className="mb-4 md:mt-3  flex justify-center lg:justify-start font-semibold uppercase md:justify-center">
                                Get In Touch
                            </h6>
                            <form className="form-inline valid" method="post">
                                <div className="relative">
                                    <div className="absolute top-3.5 left-3">
                                        <i className="fa-solid fa-envelope text-gray-400 z-20 hover:text-gray-500"></i>
                                    </div>
                                    <div className="mx-auto">
                                        <input type="text" className="h-12 w-full pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none text-black" placeholder="Enter your Email" />
                                    </div>
                                    <div className="absolute top-1.5 right-1.5">

                                        <button className="h-9 w-9 text-white rounded-lg bg-[#16192A] hover:bg-[#3c446b]"><i className="fa-solid fa-paper-plane"></i></button>

                                    </div>
                                </div>
                            </form>
                            <div className="flex justify-center mt-5">
                                <a href="#!" className="mr-6 text-neutral-200 hover:text-blue-500 dark:text-neutral-200">
                                    <i className='fa-brands fa-facebook text-xl'></i>
                                </a>
                                <a href="#!" className="mr-6 text-neutral-200 hover:text-blue-400 dark:text-neutral-200">
                                    <i className="fa-brands fa-twitter text-xl"></i>
                                </a>
                                <a href="#!" className="mr-6 text-neutral-200 hover:text-orange-400 dark:text-neutral-200">
                                    <i className="fa-brands fa-google-plus-g text-xl"></i>
                                </a>
                                <a href="#!" className="mr-6 text-neutral-200 hover:text-pink-600 dark:text-neutral-200">
                                    <i className="fa-brands fa-instagram text-xl"></i>
                                </a>
                                <a href="#!" className="mr-6 text-neutral-200 hover:text-blue-500 dark:text-neutral-200">
                                    <i className="fa-brands fa-linkedin-in text-xl"></i>
                                </a>
                                <a href="#!" className="text-neutral-200 hover:text-blue-500 dark:text-neutral-200">
                                    <i className="fa-brands fa-github text-xl"></i>
                                </a>
                            </div> */}
                        </div>



                    </div>
                </div>

                <div className="bg-transparent text-gray-200 p-6 text-center  border-t">
                    <Fade delay={100}>
                        <span>© {currentYear} Copyright : </span>
                        <a className="font-semibold text-neutral-200 dark:text-neutral-400 hover:text-[#FA9805]"
                            href="/">Layto Jobs LLP</a>
                    </Fade>
                </div>
            </div>

        </footer>
    )
}

export default Footer